<template>
  <base-section id="404">
  <base-img
      :src="require('@/assets/compac/logo.png')"
      class="mx-auto mb-12"
      contain
      height="350"
      width="250"
    />
    <base-heading 
      style="color:dimgrey" 
      class="mt-n9"
      title="! Navegaste a una página que no existe !" />

    <!--<base-subheading
      class="primary--text"
      title="Navegaste a una página que no existe!"
    />

    <base-subheading
      space="8"
      title=""
    />-->

    <div class="text-center mt-7">
      <!--<base-btn :to="{ name: 'Home' }">
        Ir a Inicio
      </base-btn>-->
      <base-btn to="/">
        Ir a Inicio
      </base-btn>
    </div>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
